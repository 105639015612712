@use 'src/responsive' as responsive;
@use 'src/styles/_utility';

html {
  height: 100%;
  background-color: var(--app-background);
  // Gap when mat-form-field subscript is static
  --form-field-row-gap: 0.5rem;
  // Gap when mat-form-field subscript is dynamic
  --form-field-no-subscript-row-gap: 1.3rem;
  //  /!\ Safari handles vw differently, use % when refering to the full page width
  --main-content-width: 60%;
  --main-content-large-width: 80%;
  --main-content-element-width: 28vw;
  --main-content-large-padding: 1rem 0;

  --widget-panel-max-width: 30vw;
  --widget-panel-width: 500px;

  --header-height: 60px;
  --standard-app-padding: 1rem;
}

lib-ol-gmap-button {
  --mat-text-button-with-icon-horizontal-padding: 0px;
}

body {
  height: 100%;
  background-color: var(--app-background);
  margin: 0;
  font-family: var(--ds-body-font);
  font-size: var(--ds-body-font-size);
}

button:not([mat-menu-item]) {
  text-transform: uppercase;
}

h1 {
  font-weight: 300;
  font-family: var(--ds-title-font);
  font-size: var(--ds-title-font-size);
}

.smv-large-card-title {
  --mat-card-title-text-weight: 300;
  --mat-card-title-text-font: var(--ds-title-font);
  --mat-card-title-text-size: var(--ds-title-font-size);

  margin-bottom: var(--mat-card-title-bottom-margin, 1rem) !important;
}

// Page layout
.smv-page-layout {
  display: block;
  text-align: center;
  width: var(--main-content-width);
  margin: 0 auto;
  padding: 1rem;
}

.smv-large-page-layout {
  @extend .smv-page-layout;
  width: var(--main-content-large-width);
}

.smv-large-page-title {
  margin: 0 auto 1rem auto;
  width: var(--main-content-large-width);

  --mat-divider-color: black;

  h1 {
    font-weight: 600;
    margin: 0;
  }
}

.smv-large-page-background {
  background-image: url('./assets/images/home_background.png');
  background-size: cover;
  background-attachment: fixed;
  height: 100%;
  padding: var(--main-content-large-padding);
  box-sizing: border-box;
}

// Button spinner
@keyframes btnSpinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.btnSpinnerElement {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: btnSpinner 1.2s linear infinite;
}

.btnSpinner {
  pointer-events: none;

  .mdc-button__label,
  mat-icon {
    visibility: hidden;
  }

  &::before {
    @extend .btnSpinnerElement;
  }

  &.mdc-fab::before {
    @extend .btnSpinnerElement;
  }
}

// Notifications
.notification-bar-success {
  --mat-mdc-snack-bar-button-color: white;
  --mdc-snackbar-container-color: #319531;
}

.notification-bar-error {
  --mat-mdc-snack-bar-button-color: white;
  --mdc-snackbar-container-color: #d51d1d;
}

.notification-bar-multiline {
  white-space: pre-wrap;
}

// Dialog
.smv-dialog {
  --mdc-dialog-subhead-line-height: 32px;
  --mdc-dialog-subhead-size: 20px;
  --mdc-dialog-subhead-tracking: 0.0125em;

  .mat-mdc-dialog-title {
    @extend .unselectable;
  }

  .mat-mdc-dialog-content {
    max-height: 70vh;
  }

  .mat-mdc-dialog-actions {
    justify-content: end;
  }
}

.smv-legal-dialog {
  @extend .smv-dialog;
  --mdc-dialog-supporting-text-size: 0.78rem;
  --mdc-dialog-supporting-text-color: var(--ds-color-black);
  --mdc-dialog-supporting-text-tracking: 0;

  max-width: 1000px;

  .last-revision {
    font-size: small;
    font-weight: 600;
  }

  p {
    text-align: justify;
    margin: 0;
    margin-bottom: 12px;
  }

  h2 {
    font-size: 1.3em;
  }

  .bold {
    font-weight: bold;
  }

  .uppercase {
    text-transform: uppercase;
  }

  .bold-uppercase {
    font-weight: bold;
    text-transform: uppercase;
  }
}

// Form
.smv-form {
  display: flex;
  flex-direction: column;
  margin: 1rem auto;
  max-width: var(--smv-form-max-width, 400px);
  gap: var(--form-field-row-gap);

  mat-slide-toggle {
    margin-bottom: var(--form-field-row-gap);
  }
}

// Tooltip

.small-tooltip {
  --mdc-plain-tooltip-supporting-text-size: small;
}

.warning-tooltip {
  --mdc-plain-tooltip-supporting-text-color: rgb(230, 0, 0);
}

// Material overrides
mat-expansion-panel {
  margin-top: 1rem;

  &.mat-expanded {
    overflow: visible;
  }
}

// Ol

.smv-btn-active {
  --ol-gmap-btn-icon-color: #d67800;
}

.smv-btn-tools-group {
  display: flex;
  flex-direction: column;

  :first-child:not(:last-child) {
    --ol-gmap-btn-bottom-left-radius: 0px;
    --ol-gmap-btn-bottom-right-radius: 0px;
  }

  :not(:first-child):not(:last-child) {
    --ol-gmap-btn-bottom-left-radius: 0px;
    --ol-gmap-btn-bottom-right-radius: 0px;
    --ol-gmap-btn-top-left-radius: 0px;
    --ol-gmap-btn-top-right-radius: 0px;
  }

  :last-child:not(:first-child) {
    --ol-gmap-btn-top-left-radius: 0px;
    --ol-gmap-btn-top-right-radius: 0px;
  }
}

.ol-marker {
  width: 20px;
  height: 20px;
  border: 1px solid #088;
  border-radius: 10px;
  background-color: rgb(200, 255, 0);
  opacity: 0.8;
}

// Responsive adjustments
@include responsive.mobile {
  html {
    --main-content-width: 90%;
    --main-content-large-width: 90%;
    --main-content-element-width: 85vw;
  }

  .smv-dialog {
    width: calc(var(--mat-dialog-container-small-max-width) - 4%);
  }
}
