/**
 * Import as: @use 'src/responsive' as responsive; 
 */

$breakpoints: (
  desktop-lg: 1920px,
  desktop-sm: 1280px,
  tablet-landscape-lg: 1280px,
  tablet-landscape-sm: 1024px,
  tablet-portrait-lg: 800px,
  tablet-portrait-sm: 768px,
  mobile-lg: 375px,
  mobile-md: 360px,
  mobile-sm: 320px,
);

@function get-screen-size($breakpoint, $strict: false, $addedValue: 0) {
  @if map-has-key($map: $breakpoints, $key: $breakpoint) {
    $screenSize: map-get(
      $map: $breakpoints,
      $key: $breakpoint,
    );

    @if $strict == true {
      $screenSize: $screenSize + $addedValue;
    }

    @return $screenSize;
  } @else {
    @error 'Invalid breakpoint name: #{$breakpoint}';
  }
}

/**
 * Screen >= breakpoint
 * Usage: @include responsive.media-above(desktop-sm)
 * 
 * Strict mode: Screen > breakpoint
 * Usage: @include responsive.media-above(desktop-sm, $strict: true)
 */
@mixin media-above($breakpoint, $strict: false) {
  @media (min-width: get-screen-size($breakpoint, $strict, 1)) {
    @content;
  }
}

/**
 * Screen <= breakpoint
 * Usage: @include responsive.media-below(desktop-lg)
 * 
 * Strict mode: Screen < breakpoint
 * Usage: @include responsive.media-below(desktop-lg, $strict: true)
 */
@mixin media-below($breakpoint, $strict: false) {
  @media (max-width: get-screen-size($breakpoint, $strict, -1)) {
    @content;
  }
}

/**
 * Lower breakpoint <= screen <= upper breakpoint
 * Usage: @include responsive.media-between(desktop-sm, mobile-md)
 * 
 * Strict mode: Lower breakpoint < screen < upper breakpoint
 * Usage: @include responsive.media-between(desktop-sm, mobile-md, $lowerStrict: true, $upperStrict: true)
 */
@mixin media-between($lowerBreakpoint, $upperBreakpoint, $lowerStrict: false, $upperStrict: false) {
  $lowerScreenSize: get-screen-size($lowerBreakpoint, $lowerStrict, 1);
  $upperScreenSize: get-screen-size($upperBreakpoint, $upperStrict, -1);

  @media (min-width: $upperScreenSize) and (max-width: $lowerScreenSize) {
    @content;
  }
}

// Usage: @include responsive.mobile { ... }
@mixin mobile() {
  @include media-below(tablet-portrait-sm, $strict: true) {
    @content;
  }
}

// Usage: @include responsive.tablet { ... }
@mixin tablet-portrait() {
  @include media-between(tablet-portrait-lg, tablet-portrait-sm) {
    @content;
  }
}
