// Global overrides that should be applied to all components

$baseExpansionPanelHeight: 35px;

@mixin material-overrides {
  --mat-card-title-text-weight: 500;
  --mat-card-title-text-size: 1.2em;

  --mdc-elevated-card-container-elevation: 0px 4px 12px 0px #00000026; // box-shadow
  --mdc-elevated-card-container-shape: var(--ds-rounded-radius); // border-radius

  --mat-expansion-header-expanded-state-height: #{$baseExpansionPanelHeight};
  --mat-expansion-header-collapsed-state-height: #{$baseExpansionPanelHeight};
  --mat-expansion-header-text-weight: 600;

  --mat-form-field-subscript-text-size: 0.7rem;

  // mat-tables seem to ignore the global typography
  --mat-table-header-headline-font: var(--ds-body-font);
  --mat-table-row-item-label-text-font: var(--ds-body-font);
  --mat-table-footer-supporting-text-font: var(--ds-body-font);

  --mat-paginator-container-text-size: 0.75rem;

  --mdc-dialog-supporting-text-color: #484848;

  // Responsive design
  --mat-dialog-container-small-max-width: 90vw;
}

// =====================================================
// Specific design
// =====================================================

// Basic expansion panel with a white title background (default design)
.smv-expansion-panel-flat {
  box-shadow: none !important;
  border: solid 1px var(--ds-card-gray-border);

  --mat-expansion-header-expanded-state-height: #{$baseExpansionPanelHeight};
  --mat-expansion-header-collapsed-state-height: #{$baseExpansionPanelHeight};

  .mat-expansion-panel-content {
    border-top: solid 1px var(--ds-card-gray-border);
  }

  .mat-expansion-panel-body {
    padding: 10px 15px;
  }
}

// Expansion panel with a grayish title background, should be used for panels
// containing sub-panels
.smv-expansion-parent-panel-flat {
  @extend .smv-expansion-panel-flat;

  --mat-expansion-header-expanded-state-height: 45px;
  --mat-expansion-header-collapsed-state-height: 45px;

  & > .mat-expansion-panel-header {
    background-color: #f3f4f6 !important;
  }
}

mat-expansion-panel {
  @extend .smv-expansion-panel-flat;
}

.mdc-text-field--outlined {
  background-color: white;
  border-radius: 4px !important;
}

mat-card {
  mat-card-content {
    padding: var(--mat-card-content-padding, 0 1rem) !important;
  }

  mat-card-actions {
    padding: var(--mat-card-actions-padding, 1rem) !important;
    justify-content: right;
  }
}
