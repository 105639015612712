/* To be used for basic common styling - keep using vanilla CSS for more complex design */

.smv-flex-between {
  display: flex;
  justify-content: space-between;
}

.smv-flex-col {
  display: flex;
  flex-direction: column;
}


.smv-sticky-bottom {
  background-color: var(--smv-sticky-background, white);
  padding: var(--smv-sticky-bottom-padding, 0.5rem);
  position: sticky;
  bottom: 0;
  z-index: var(--smv-sticky-bottom-zindex, 10);
}

.smv-sticky-bottom-actions {
  @extend .smv-sticky-bottom;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 0.5rem;
}

.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}