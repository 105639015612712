@use '@angular/material' as mat;
@use '_app-palettes' as palettes;
@use '_design-system';
@use '_material-overrides' as overrides;

@include mat.core();

@function appTheme($palette) {
  @return mat.m2-define-light-theme(
    (
      color: (
        primary: $palette,
        accent: $palette,
        warn: mat.m2-define-palette(mat.$m2-red-palette),
      ),
      typography: (
        plain-family: 'Noto Sans',
        brand-family: 'Anek Tamil',
      ),
    )
  );
}

@mixin apply-palette($palette) {
  @include mat.all-component-colors(appTheme(mat.m2-define-palette($palette)));

  // Overrides must be included again otherwise the new theme will take precedence
  @include overrides.material-overrides;
}

@include mat.all-component-themes(appTheme(mat.m2-define-palette(palettes.$default)));

:root {
  @include overrides.material-overrides;

  --ds-color-primary: #{map-get(palettes.$default, 500)};
}

// =======================================================================
// To use a custom palette for an app, define a class with its app code
// =======================================================================

.EUDEMIS {
  @include apply-palette(palettes.$icare);
}

.CHAMP_PROPRE {
  --ds-color-primary: #{map-get(palettes.$champPropre, 500)};

  @include apply-palette(palettes.$champPropre);
}
