@use 'src/responsive' as responsive;

:root {
  --app-background: #ffffff;

  --ds-color-syngenta-navy: #00004b;
  --ds-color-syngenta-navy-contrast: white;
  --ds-color-cropwise-green: #73dc78;
  --ds-color-cropwise-green-contrast: white;
  --ds-color-white: #ffffff;
  --ds-color-white-contrast: black;
  --ds-color-black: #000000;
  --ds-color-black-contrast: white;
  --ds-color-chive-flower: #7850c0;
  --ds-color-chive-flower-contrast: white;
  --ds-color-apples: #c13355;
  --ds-color-apples-contrast: white;
  --ds-color-oil-colza: #d6c54d;
  --ds-color-oil-colza-contrast: white;
  --ds-color-beetroot: #701c6a;
  --ds-color-beetroot-contrast: white;

  --ds-body-font: 'Noto Sans', sans-serif;
  --ds-body-font-size: 0.875rem; // 14px
  --ds-title-font: 'Anek Tamil', sans-serif;
  --ds-title-font-size: 2rem;

  --ds-rounded-radius: 6px;

  --ds-card-gray-background: #dfe2e7;
  --ds-card-gray-border: #c2c7d0;

  --ds-expansion-panel-spacing: 0.5rem;
}

// Responsive adjustments
@include responsive.mobile {
  :root {
    --ds-title-font-size: 1.7rem;
  }
}
